






















import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import Title from '@/components/common/page-layout/Title.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'

@Component({
  components: {
    PageLayout,
    ContentArea,
    Title,
    PrimaryButton
  }
})

export default class Succes extends Vue {
  private routeName = 'demographic welcome'
  private created (): void {
    this.$store.dispatch('auth/authorize')
      .then(this.$store.getters['auth/authorizationOutcome'])
    const data = this.$store.getters['auth/getUserSessionData']
    if ('is_demographic_filled' in data && 'fullName' in data && 'passportNumber' in data) {
      this.routeName = 'demographic welcome'
      const userInfo = {
        fullName: data.fullName,
        passportNumber: data.passportNumber,
        flyingDate: data.flyingDate
      }
      this.$store.dispatch('walkthrough/setUserInfo', userInfo)
    }
  }

  private mounted (): void {
    if (window.innerWidth < 767) {
      this.$root.$on('mobile-go-back', () => {
        this.goBack()
      })
    }
  }

  private destroyed (): void {
    sessionStorage.removeItem('isAgree')
  }

  private goBack (): void {
    this.$router.go(-1)
  }
}
